import { Datagrid, DateField, List, TextField, Edit, SimpleForm, TextInput, DateTimeInput, SelectInput } from 'react-admin';

const taskFilters = [
  <TextInput source="q" label="Search" alwaysOn />,
  <SelectInput source="status" label="Status" />,
];

export const TaskList = () => (
  <List filters={taskFilters}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <DateField source="dueDate" showTime />
      <TextField source="timeZone" />
      <TextField source="status" />
      <TextField source="model" />
      <TextField source="query" />
      <TextField source="handler" />
      <TextField source="dueDateHandler" />
      <TextField source="cronString" />
      <DateField source="beginDate" />
      <DateField source="endDate" />
      <TextField source="log" />
    </Datagrid>
  </List>
);

export const TaskEdit = () => (
  <Edit>
      <SimpleForm>
          <TextInput source="_id" disabled />
          <TextInput source="id" disabled />
          <DateTimeInput source="dueDate" />
          <TextInput source="cronString" />
          <TextInput source="model" disabled />
          <TextInput source="query" disabled />
          <TextInput source="handler" disabled />
          <TextInput source="dueDateHandler" disabled />
          <TextInput source="timeZone" disabled />
          <TextInput source="status" disabled />
      </SimpleForm>
  </Edit>
);