import { Create, Datagrid, Edit, ImageField, ImageInput, List, NumberField, NumberInput, SimpleForm, TextField, TextInput } from "react-admin";

export const AdBannerCreate = () => (
  <Create>
    <SimpleForm>
      <ImageInput source="image">
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput source="redirectionUrl" />
      <NumberInput source="displayTime" />
      <NumberInput 
        source="displayProbability"
      />
    </SimpleForm>
  </Create>
);

export const AdBannerList = () => (
  <List>
    <Datagrid rowClick="edit">
      <ImageField source="image" />
      <TextField source="redirectionUrl" />
      <NumberField source="displayTime" />
      <NumberField source="displayProbability" />
      <NumberField source="displayCount" />
    </Datagrid>
  </List>
);

export const AdBannerEdit = () => (
  <Edit>
    <SimpleForm>
      <ImageInput source="image">
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput source="redirectionUrl" />
      <NumberInput source="displayTime" />
      <NumberInput 
        source="displayProbability"
      />
      <NumberInput source="displayCount" disabled/>
    </SimpleForm>
  </Edit>
);