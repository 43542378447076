import React from 'react';
import { BooleanField, BooleanInput, Datagrid, DateField, Edit, List, NumberField, NumberInput, ReferenceArrayField, ReferenceArrayInput, ReferenceField, ReferenceInput, SelectInput, SimpleForm, TextField, TextInput } from 'react-admin';

export const LeagueList = () => (
    <List>
        <Datagrid rowClick="edit">
            <DateField source="createdAt" />
            <TextField source="name" />
            <TextField source="status" />
            <ReferenceArrayField source="users" reference="user" />
            <NumberField source="mercatoRound" />
            <NumberField source="currentRound" />
            <NumberField source="nbRounds" />
            <BooleanField source="isPremium" />
            <ReferenceField source="championship" reference="championship" />
            <ReferenceField source="championshipSeason" reference="championshipSeason" />
            <NumberField source="nbPlayers" />
            <TextField source="type" />
            <ReferenceField source="master" reference="user" />
            {/* <ReferenceArrayField source="restrictedMercatoPlayers" reference="player" /> */}
            <TextField source="invitationCode" />
        </Datagrid>
    </List>
);

export const LeagueEdit = () => (
  <Edit>
      <SimpleForm>
          <TextInput source="id" disabled />
          <TextInput source="name" />
          <TextInput source="status" />
          <ReferenceArrayInput source="users" reference="user" />
          <NumberInput source="mercatoRound" />
          <NumberInput source="currentRound" />
          <NumberInput source="nbRounds" />
          <BooleanInput source="isPremium" />
          <ReferenceInput source="championship" reference="championship" />
          <ReferenceInput source="championshipSeason" reference="championshipSeason" />
          <NumberInput source="nbPlayers" />
          <SelectInput source="type" choices={[{ id: 'normal', name: 'Normal' }, { id: 'playoffs', name: 'Playoffs' }]} required />
          <ReferenceInput source="master" reference="user" />
          {/* <ReferenceArrayInput source="restrictedMercatoPlayers" reference="player" /> */}
          <TextInput source="invitationCode" />
      </SimpleForm>
  </Edit>
);