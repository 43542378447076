import React from 'react';
import { BooleanField, Datagrid, DateField, List, NumberField, ReferenceField, TextField } from 'react-admin';
import { BooleanInput, DateInput, Edit, NumberInput, ReferenceInput, SimpleForm, TextInput } from 'react-admin';
import { SubReferenceInput } from '../components/SubReferenceInput';

const realgameFilters = [
    <TextInput source="q" label="Search" alwaysOn />,
    <ReferenceInput source="championship" label="Championship" reference="championship" />,
    <SubReferenceInput
        source="championshipSeason"
        label="Championship season"
        reference="championshipSeason"
        filterFieldValue="championship"
        destinationField="championship"
        sort={{ field: 'season', order: 'ASC' }}
    />,
]

export const RealgameList = () => (
    <List filters={realgameFilters}>
        <Datagrid rowClick="edit">
            <BooleanField source="isPopulated" />
            <BooleanField source="statsProcessed" />
            <ReferenceField source="championship" reference="championship"  />
            <ReferenceField source="championshipSeason" reference="championshipSeason" />
            <NumberField source="round" reference="round" />
            <TextField source="roundType" />
            <DateField source="startDate" />
            <TextField source="status" />
            <ReferenceField source="teamHome" reference="team" />
            <ReferenceField source="teamAway" reference="team" />
            <NumberField source="difficultyHome" />
            <NumberField source="difficultyAway" />
            <NumberField source="scoreHome" />
            <NumberField source="scoreAway" />
            <NumberField source="nTriesAway" />
            <NumberField source="nTriesHome" />
        </Datagrid>
    </List>
);

export const RealgameEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="_id" disabled />
            <TextInput source="optaId" disabled />
            <ReferenceInput source="championshipSeason" reference="championshipSeason" />
            <BooleanInput source="isPopulated" />
            <BooleanInput source="statsProcessed" />
            <NumberInput source="round" />
            <TextInput source="roundType" />
            <DateInput source="startDate" />
            <TextInput source="status" />
            <ReferenceInput source="teamHome" reference="team" />
            <ReferenceInput source="teamAway" reference="team" />
            <NumberInput source="difficultyHome" />
            <NumberInput source="difficultyAway" />
            <NumberInput source="scoreHome" />
            <NumberInput source="scoreAway" />
            <TextInput source="group" />
            <TextInput source="composition" />
            <ReferenceInput source="championship" reference="championship" />
            <TextInput source="id" />
        </SimpleForm>
    </Edit>
);