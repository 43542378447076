import React from 'react';
import { BooleanField, BooleanInput, Datagrid, DateField, DateInput, Edit, List, NumberField, NumberInput, ReferenceField, ReferenceInput, SelectField, SimpleForm, TextField, TextInput } from 'react-admin';

export const VirtualgameList = () => (
    <List>
        <Datagrid rowClick="edit">
            <DateField source="startDate" />
            <SelectField source="status" choices={[{ id: 'fixture', name: 'Fixture' }, { id: 'result', name: 'Result' }]} />
            <ReferenceField source="league" reference="league" />
            <ReferenceField source="teamHome" reference="virtualTeam" />
            <ReferenceField source="teamAway" reference="virtualTeam" />
            <NumberField source="virtualRound" />
            <NumberField source="realRound" />
            <NumberField source="homeStats.pointsFor" />
            <NumberField source="awayStats.pointsFor" />
        </Datagrid>
    </List>
);

export const VirtualgameEdit = () => (
  <Edit>
      <SimpleForm>
          <TextInput source="id" disabled />
          <DateInput source="startDate" />
          <TextInput source="status" />
          <ReferenceInput source="league" reference="league" />
          <ReferenceInput source="teamHome" reference="virtualTeam" />
          <ReferenceInput source="teamAway" reference="virtualTeam" />
          <NumberInput source="virtualRound" />
          <NumberInput source="realRound" />
          <TextInput source="roundType" />
      </SimpleForm>
  </Edit>
);