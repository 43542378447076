import React from 'react';
import { Datagrid, ImageField, ImageInput, List, TextField } from 'react-admin';
import { Edit, SimpleForm, TextInput } from 'react-admin';

export const TeamList = () => (
    <List>
        <Datagrid rowClick="edit">
            <TextField source="optaId" />
            <TextField source="name" />
            <TextField source="jerseyTextColor" />
            <TextField source="onlineShopUrl" />
            <TextField source="onlineTicketUrl" />
            <TextField source="shortName" />
            <ImageField source="image" />
            <ImageField source="jerseyBack" />
        </Datagrid>
    </List>
);

export const TeamEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="_id" disabled />
            <TextInput source="optaId" disabled />
            <TextInput source="name" />
            <TextInput source="jerseyTextColor" />
            <TextInput source="onlineShopUrl" />
            <TextInput source="onlineTicketUrl" />
            <TextInput source="shortName" />
            <ImageInput source="image">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput source="jerseyBack">
                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Edit>
);