import React from 'react';
import { Admin, Resource, EditGuesser } from 'react-admin';

import { authProvider } from './authProvider';
import dataProvider from './dataProvider';
import { ChampionshipEdit, ChampionshipList } from './models/championships';
import { ChampionshipseasonList } from './models/championshipSeason';
import { PlayerEdit, PlayerList } from './models/players';
import { RealgameEdit, RealgameList } from './models/realGames';
import { TeamEdit, TeamList } from './models/teams';
import { UserList, UserEdit, UserCreate } from './models/users';
import { LeagueEdit, LeagueList } from './models/leagues';
import { VirtualteamEdit, VirtualteamList } from './models/virtualTeams';
import { VirtualgameEdit, VirtualgameList } from './models/virtualGames';
import { StockimageCreate, StockimageEdit, StockimageList } from './models/stockImages';
import { TaskEdit, TaskList } from './models/tasks';
import { AdBannerCreate, AdBannerEdit, AdBannerList } from './models/adBanners';


const App = () => (
  <Admin
    dataProvider={dataProvider}
    authProvider={authProvider}
  >
    <Resource
      name="stockImage"
      list={StockimageList}
      edit={StockimageEdit}
      create={StockimageCreate}
      recordRepresentation="uid"
    />
    <Resource
      name="championship"
      list={ChampionshipList}
      edit={ChampionshipEdit}
      recordRepresentation="name"
    />
    <Resource
      name="championshipSeason"
      list={ChampionshipseasonList}
      edit={EditGuesser}
      recordRepresentation={(record) => record.season}
    />
    <Resource
      name="realGame"
      list={RealgameList}
      edit={RealgameEdit}
    />
    <Resource
      name="team"
      list={TeamList}
      edit={TeamEdit}
      recordRepresentation="name"
    />
    <Resource
      name="position"
      recordRepresentation="number"
    />
    <Resource
      name="player"
      list={PlayerList}
      edit={PlayerEdit}
      recordRepresentation={record => `${record.firstName} ${record.lastName}`}
    />
    <Resource
      name="user"
      list={UserList}
      edit={UserEdit}
      create={UserCreate}
      recordRepresentation="username" //{record => `${record.firstName} ${record.lastName}`}
    />
    <Resource
      name="league"
      list={LeagueList}
      edit={LeagueEdit}
      recordRepresentation="name"
    />
    <Resource
      name="virtualTeam"
      list={VirtualteamList}
      edit={VirtualteamEdit}
      recordRepresentation="teamName"
    />
    <Resource
      name="virtualGame"
      list={VirtualgameList}
      edit={VirtualgameEdit}
      // resourceRepresentation=""
    />
    <Resource
      name="task"
      list={TaskList}
      edit={TaskEdit}
    />
    <Resource
      name="adBanner"
      list={AdBannerList}
      create={AdBannerCreate}
      edit={AdBannerEdit}
    />
  </Admin>
);

export default App;
