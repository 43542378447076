import React from 'react';
import { Datagrid, DateField, List, NumberField, ReferenceArrayField, ReferenceField } from 'react-admin';

export const ChampionshipseasonList = () => (
    <List>
        <Datagrid rowClick="edit">
            <ReferenceArrayField source="teams" reference="team" />
            <ReferenceField source="championship" reference="championship" />
            <NumberField source="season" />
            <NumberField source="currentRound" />
            <NumberField source="nRounds" />
            <DateField source="startDate" />
        </Datagrid>
    </List>
);