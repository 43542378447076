import React from 'react';
import { BooleanField, Datagrid, DateField, EmailField, List, TextField, BooleanInput, DateInput, Edit, NumberInput, SimpleForm, TextInput, Create, SelectInput, TopToolbar, FilterButton, CreateButton, ExportButton } from 'react-admin';

const UserListActions = () => (
    <TopToolbar>
        <FilterButton />
        <CreateButton/>
        <ExportButton maxResults={0}/> {/* If maxResult set to 0, all data will be exported */}
    </TopToolbar>
);

const userFilters = [
    <TextInput source="q" label="Search" alwaysOn />,
    <BooleanInput source="newsletterLnr" label="Newsletter LNR" />,
    <BooleanInput source="newsletterLandRover" label="Newsletter Land Rover" />,
    <SelectInput 
        source="kind" 
        label="Kind" 
        choices={[
            { id: 'email', name: 'email' },
            { id: 'facebook', name: 'facebook' },
            { id: 'twitter', name: 'twitter' },
            { id: 'google', name: 'google' },
            { id: 'appleID', name: 'appleID' },
            { id: 'fake', name: 'fake' },
				]} />,
];

export const UserList = () => (
    <List filters={userFilters} actions={<UserListActions />}>
        <Datagrid rowClick="edit">
            <DateField source="createdAt" />
            <TextField source="kind" />
            <TextField source="firstName" />
            <TextField source="lastName" />
            <EmailField source="email" />
            <TextField source="gender" />
            <DateField source="dateOfBirth" />
            <TextField source="country" />
            <TextField source="city" />
            <TextField source="locale" />
            <TextField source="username" />
            <DateField source="lastLogin" />
            <BooleanField source="betaTester" />
            <BooleanField source="admin" />
            <BooleanField source="newsletterLnr" />
            <BooleanField source="newsletterLandRover" />
        </Datagrid>
    </List>
);

export const UserEdit = () => (
    <Edit>
        <SimpleForm>
            <BooleanInput source="subscriptions.pickYourTeamAlert_fr" />
            <TextInput source="kind" />
            <TextInput source="deviceTokens" />
            <TextInput source="firstName" />
            <TextInput source="lastName" />
            <TextInput source="email" />
            <TextInput source="gender" />
            <DateInput source="dateOfBirth" />
            <TextInput source="country" />
            <TextInput source="city" />
            <TextInput source="favoriteTeam" />
            <DateInput source="additionalTeam" />
            <TextInput source="locale" />
            <TextInput source="username" />
            <TextInput source="token" />
            <DateInput source="lastLogin" />
            <NumberInput source="__v" />
            <DateInput source="endOfLivePremiumDate" />
            <DateInput source="readNotificationsTime" />
            <BooleanInput source="betaTester" />
            <BooleanInput source="admin" />
            <DateInput source="resetRequestTime" />
            <TextInput source="resetToken" />
            <TextInput source="id" />
            <BooleanInput source="newsletterLnr" />
            <BooleanInput source="newsletterLandRover" />
        </SimpleForm>
    </Edit>
);

export const UserCreate = () => (
    <Create>
        <SimpleForm>
            <BooleanInput source="subscriptions.pickYourTeamAlert_fr" />
            <TextInput source="kind" />
            <TextInput source="firstName" />
            <TextInput source="lastName" />
            <TextInput source="email" />
            <TextInput source="password" />
            <TextInput source="gender" />
            <DateInput source="dateOfBirth" />
            <TextInput source="country" />
            <TextInput source="city" />
            <TextInput source="favoriteTeam" />
            <DateInput source="additionalTeam" />
            <TextInput source="username" />
            <DateInput source="endOfLivePremiumDate" />
            <BooleanInput source="betaTester" />
            <BooleanInput source="admin" />
        </SimpleForm>
    </Create>
);