import React from 'react';
import { Create, Datagrid, DateField, Edit, ImageField, ImageInput, List, NumberField, NumberInput, ReferenceField, SimpleForm, TextField } from 'react-admin';

export const StockimageList = () => (
    <List>
        <Datagrid rowClick="edit">
            <NumberField source="uid" />
            <ImageField source="image" />
        </Datagrid>
    </List>
);

export const StockimageEdit = () => (
  <Edit>
    <SimpleForm>
      <NumberInput source="uid" />
      <ImageInput source="image">
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

export const StockimageCreate = () => (
  <Create>
    <SimpleForm>
      <NumberInput source="uid" />
      <ImageInput source="image">
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
);