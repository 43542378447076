import React from 'react';
import { ArrayField, ArrayInput, BooleanField, BooleanInput, ChipField, Datagrid, DateField, DateInput, Edit, List, NumberField, NumberInput, ReferenceField, ReferenceInput, SimpleForm, SimpleFormIterator, SingleFieldList, TextField, TextInput } from 'react-admin';

const filters = [
  <TextInput source="q" label="Search" alwaysOn />,
  <ReferenceInput source="league" reference="league" />,
];

export const VirtualteamList = () => (
    <List filters={filters}>
        <Datagrid rowClick="edit">
            <TextField source="teamName" />
            <NumberField source="bonuses.double captain" />
            <NumberField source="bonuses.16th man" />
            <NumberField source="bonuses.medical joker" />
            <NumberField source="bonuses.wild card" />
            <NumberField source="money" />
            <NumberField source="displayedMoney" />
            <TextField source="mercatoStatus" />
            <TextField source="standing" />
            <BooleanField source="hasCompauto" />
            <BooleanField source="extraBonusPurchased" />
            <ReferenceField source="user" reference="user" />
            <ReferenceField source="league" reference="league" />
            <TextField source="teamLogoType" />
        </Datagrid>
    </List>
);

export const VirtualteamEdit = () => (
  <Edit>
      <SimpleForm>
          <TextInput source="id" disabled />
          <NumberInput source="bonuses.double captain" />
          <NumberInput source="bonuses.16th man" />
          <NumberInput source="bonuses.medical joker" />
          <NumberInput source="bonuses.wild card" />
          <NumberInput source="money" />
          <NumberInput source="displayedMoney" />
          <TextInput source="mercatoStatus" />
          <TextInput source="standing" />
          <BooleanInput source="hasCompauto" />
          <BooleanInput source="extraBonusPurchased" />
          <ReferenceInput source="user" reference="user" />
          <ReferenceInput source="league" reference="league" />
          <TextInput source="teamName" />
          <ArrayInput source="composition">
            <SimpleFormIterator>
              <ReferenceInput source="player" reference="player" />
              <ReferenceInput source="position" reference="position" />
              <NumberInput source="purchasePrice" />
            </SimpleFormIterator>
          </ArrayInput>
      </SimpleForm>
  </Edit>
);