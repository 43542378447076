import React from 'react';
import { Datagrid, ImageField, ImageInput, List, NumberField, ReferenceArrayField, TextField } from 'react-admin';
import { Edit, NumberInput, ReferenceInput, SimpleForm, TextInput } from 'react-admin';

const playerFilters = [
  <TextInput source="q" label="Search" alwaysOn />,
  <ReferenceInput source="positions" label="Position" reference="position" />,
];

export const PlayerList = () => (
    <List filters={playerFilters}>
        <Datagrid rowClick="edit">
            <ReferenceArrayField source="positions" reference="position" />
            <NumberField source="price" />
            <TextField source="form" />
            <TextField source="optaId" />
            <TextField source="firstName" />
            <TextField source="lastName" />
            <TextField source="id" />
            {/* <ImageField source="image" /> */}
        </Datagrid>
    </List>
);


export const PlayerEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="optaId" disabled  />
            <TextInput source="firstName" />
            <TextInput source="lastName" />
            <TextInput source="form" />
            <NumberInput source="price" />
            <ReferenceArrayField source="positions" reference="position" />
            {/* <ImageInput source="image">
                <ImageField source="src" title="title" />
            </ImageInput> */}
        </SimpleForm>
    </Edit>
);