import React from 'react';
import { Datagrid, ImageField, ImageInput, List, NumberField, ReferenceField, SelectField, SelectInput, TextField } from 'react-admin';
import { Edit, NumberInput, ReferenceInput, SimpleForm, TextInput } from 'react-admin';

export const ChampionshipList = () => (
    <List>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <TextField source="displayName" />
            <TextField source="optaId" />
            <SelectField
                source="compositionDeadline.day"
                choices={[
                    { id: 1, name: 'Lundi' },
                    { id: 2, name: 'Mardi' },
                    { id: 3, name: 'Mercredi' },
                    { id: 4, name: 'Jeudi' },
                    { id: 5, name: 'Vendredi' },
                    { id: 6, name: 'Samedi' },
                    { id: 0, name: 'Dimanche' },
                ]}
            />
            <NumberField source="compositionDeadline.hour" />
            <ReferenceField source="currentSeason" reference="championshipSeason" />
            <ImageField source="image" />
        </Datagrid>
    </List>
);

export const ChampionshipEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="displayName" />
            <TextInput source="optaId" />
            <SelectInput
                source="compositionDeadline.day"
                choices={[
                    { id: 1, name: 'Lundi' },
                    { id: 2, name: 'Mardi' },
                    { id: 3, name: 'Mercredi' },
                    { id: 4, name: 'Jeudi' },
                    { id: 5, name: 'Vendredi' },
                    { id: 6, name: 'Samedi' },
                    { id: 0, name: 'Dimanche' },
                ]}
            />
            <NumberInput source="compositionDeadline.hour" />
            <NumberInput source="sequence" />
            <ReferenceInput source="currentSeason" reference="championshipSeason" />
            <ImageInput source="image">
                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Edit>
);