import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import config from './config.json';

const apiUrl = config.SERVER_URL;
const httpClient = fetchUtils.fetchJson;

const getToken = () => {
  return localStorage.getItem('token');
}

const capitalize = (resource) => resource.charAt(0).toUpperCase() + resource.slice(1);

const RESOURCE_IMAGE_MAPPING = {
  'championship': [
    { path: 'image', title: 'Image' },
  ],
  'player': [
    { path: 'image', title: 'Image' },
  ],
  'team': [
    { path: 'image', title: 'Image' },
    { path: 'jerseyBack', title: 'Jersey' },
  ],
  'stockImage': [
    { path: 'image', title: 'Image' },
  ],
  'adBanner': [
    { path: 'image', title: 'Image' },
  ],
};

const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        }
        reader.onerror = reject;
        
        reader.readAsDataURL(file.rawFile);
    });

const methods = {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: [field, order],
            range: [(page - 1) * perPage, page * perPage - 1],
            filter: params.filter,
        };
        const url = `${apiUrl}/api/method/CRUD/${resource}s`;

        console.log(query);

        return httpClient(url, { 
          method: 'POST', 
          body: JSON.stringify(query),
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`
          })
        }).then(({ headers, json }) => {
          if (RESOURCE_IMAGE_MAPPING[resource] !== undefined) {
            for (let i = 0; i < RESOURCE_IMAGE_MAPPING[resource].length; ++i) {
              const mapping = RESOURCE_IMAGE_MAPPING[resource][i];
              json.data.forEach(entry => {
                entry[mapping.path] = `${config.SERVER_URL}${entry[mapping.path]}?bearer=${localStorage.getItem('token')}`;
              });
            }
          }

          console.log(json);

          return ({
            data: json.data.map(entry => ({ id: entry.id || entry._id, ...entry })),
            total: json.total,
          });
        });
    },

    getOne: (resource, params) => {
      return httpClient(`${apiUrl}/api/method/CRUD/${resource}`, {
        method: 'POST',
        body: JSON.stringify({ id: params.id }),
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`
        })
      }).then(({ json }) => {
        if (RESOURCE_IMAGE_MAPPING[resource] !== undefined) {
          for (let i = 0; i < RESOURCE_IMAGE_MAPPING[resource].length; ++i) {
            const mapping = RESOURCE_IMAGE_MAPPING[resource][i];
            console.log(json[mapping.path]);
            json[mapping.path] = {
              src: `${config.SERVER_URL}${json[mapping.path]}?bearer=${localStorage.getItem('token')}`,
              title: mapping.title,
            };
          }
        }
        return {
          data: { ...json, id: json.id || json._id },
        }
      });
    },

    getMany: (resource, params) => {
        const query = {
          filter: {
            ...params.filter,
            _id: { $in: params.ids },
          },
      };
        const url = `${apiUrl}/api/method/CRUD/${resource}s`;
        return httpClient(url, {
          method: 'POST',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`
          }),
          body: JSON.stringify(query)
        }).then(({ json }) => {
          return { data: json.data.map(i => ({ ...i, id: i.id || i._id })) };
        });
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: [field, order],
            range: [(page - 1) * perPage, page * perPage - 1],
            filter: {
                ...params.filter,
                [params.target]: params.id,
            },
        };
        const url = `${apiUrl}/api/method/CRUD/${resource}s?${stringify(query)}`;

        return httpClient(url, {
          method: 'POST',
          body: JSON.stringify(query),
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`
          }),
        }).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    create: async (resource, params) => {
      if (RESOURCE_IMAGE_MAPPING[resource] !== undefined) {
        for (let i = 0; i < RESOURCE_IMAGE_MAPPING[resource].length; ++i) {
          const mapping = RESOURCE_IMAGE_MAPPING[resource][i];
          if (params.data[mapping.path].rawFile instanceof File)
            params.data[mapping.path] = await convertFileToBase64(params.data[mapping.path]);
          else 
            delete params.data[mapping.path];
        }
      }

      return httpClient(`${apiUrl}/api/method/CRUD/create${capitalize(resource)}`, {
          method: 'POST',
          body: JSON.stringify({
            values: params.data
          }),
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`
          }),
      }).then(({ json }) => ({
          data: { ...params.data, id: json.id || json._id },
      }))
    },

    update: async (resource, params) => {
      if (RESOURCE_IMAGE_MAPPING[resource] !== undefined) {
        for (let i = 0; i < RESOURCE_IMAGE_MAPPING[resource].length; ++i) {
          const mapping = RESOURCE_IMAGE_MAPPING[resource][i];
          if (params.data[mapping.path].rawFile instanceof File)
            params.data[mapping.path] = await convertFileToBase64(params.data[mapping.path]);
          else 
            delete params.data[mapping.path];
        }
      }

      return httpClient(`${apiUrl}/api/method/CRUD/edit${capitalize(resource)}`, {
        method: 'POST',
        body: JSON.stringify({
          id: params.id,
          values: params.data
        }),
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`
        }),
      }).then(({ json }) => ({ data: { ...json, id: json.id || json._id } }));
    },
      
    updateMany: (resource, params) => {
        const query = {
            filter: { _id: { $in: params.ids }},
        };
        return httpClient(`${apiUrl}/api/method/CRUD/update${capitalize(resource)}s`, {
            method: 'POST',
            body: JSON.stringify({
              query,
              values: params.data}
            ),
            headers: new Headers({
              Authorization: `Bearer ${getToken()}`
            }),
        }).then(({ json }) => ({ data: json }));
    },

    delete: (resource, params) =>
        httpClient(`${apiUrl}/api/method/CRUD/delete${capitalize(resource)}`, {
            method: 'POST',
            headers: new Headers({
              Authorization: `Bearer ${getToken()}`
            }),
            body: JSON.stringify({
              id: params.id,
            }),
        }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) => {
        const query = {
            ids: params.ids
        };
        return httpClient(`${apiUrl}/api/method/CRUD/delete${capitalize(resource)}s`, {
            method: 'POST',
            headers: new Headers({
              Authorization: `Bearer ${getToken()}`
            }),
            body: JSON.stringify({
              query,
              data: params.data,
            }),
        }).then(({ json }) => ({ data: json }));
    },
};

export default methods;